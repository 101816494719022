<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br />
    <div class="container fluid">
      <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/screen_printer/sp3_c">
          <div class="card" style="width:260px;height:240px;">
          
            <img
              style="max-height:80%; max-width:80%;margin:auto;"
              class="card-img-top img-fluid"
              src="../../../../assets/images/product/main/production_equipement/Screen_Printer/SP3-C.jpg"
              alt="Card image cap"
            />
            
            <div class="card-body" >
              <h4 style="text-align: center; " class="card-title">
                SP3-C
              </h4>
              <h6  style="text-align: center ;font-size: 10px;" class="card-title">
               Screen printing the future
              </h6>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/screen_printer/sp1_c">
          <div class="card" style="width:260px;height:240px;">
            <img
            style="max-height:80%; max-width:80%; margin:auto;"
              class="card-img-top img-fluid"
              src="../../../../assets/images/product/main/production_equipement/Screen_Printer/SP1-C.jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                SP1-C
              </h4>
              <h6  style="text-align: center ;font-size: 10px;" class="card-title">
               Compact High Performance
              </h6>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/screen_printer/sp1_w">
          <div class="card" style="width:260px;height:240px;">
            <img
            style="max-height:80%; max-width:80%; margin:auto;"
              class="card-img-top img-fluid"
              src="../../../../assets/images/product/main/production_equipement/Screen_Printer/SP1-W.jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
               SP1-W
              </h4>
              <h6  style="text-align: center ;font-size: 10px;" class="card-title">
              Compact High Performance
              </h6>
            </div>
          </div>
          </router-link>
        </div>
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link
           to="/production_equipement/screen_printer/sp2_c">
          <div class="card" style="width:260px;height:240px;">
            <img
            style="max-height:80%; max-width:80%; margin:auto;"
              class="card-img-top img-fluid"
              src="../../../../assets/images/product/main/production_equipement/Screen_Printer/SP2-C.jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                SP2-C
              </h4>
              <h6  style="text-align: center ;font-size: 10px;" class="card-title">
              Compact High Performance
              </h6>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
      </div>
      <div class="row" style="margin-top: 30px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/screen_printer/versaprint_2_ultra3">
          <div class="card" style="width:260px;height:240px;">
            <img
            style="max-height:80%; max-width:80%; margin:auto;"
              class="card-img-top img-fluid"
              src="../../../../assets/images/product/main/production_equipement/Screen_Printer/VERSAPRINT 2 ULTRA3.png"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
               VERSAPRINT 2 ULTRA3
              </h4>
              <h6  style="text-align: center ;font-size: 10px;" class="card-title">
              Stencil printer and 3D-SPI in one system with latest measurement technology
              </h6>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
         <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/screen_printer/versaprint_2_pro_2">
          <div class="card" style="width:260px;height:240px;">
            <img
            style="max-height:80%; max-width:80%;"
              class="card-img-top img-fluid"
              src="../../../../assets/images/product/main/production_equipement/Screen_Printer/VERSAPRINT 2 PRO2.png"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                VERSAPRINT 2 PRO2
              </h4>
              <h6  style="text-align: center ;font-size: 10px;" class="card-title">
               World's first stencil printer with integrated 3D inspection
              </h6>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
        
         <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/screen_printer/versaprint_2_elite_plus">
          <div class="card" style="width:260px;height:240px;">
            <img
            style="max-height:80%; max-width:80%; margin:auto;"
              class="card-img-top img-fluid"
              src="../../../../assets/images/product/main/production_equipement/Screen_Printer/VERSAPRINT 2 ELITE plus.png"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                VERSAPRINT 2 ELITE plus 
              </h4>
              <h6  style="text-align: center ;font-size: 10px;" class="card-title">
               Ideal printer for line production without 100% inspection
              </h6>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
        
         <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/screen_printer/versaprint_2_elite">
          <div class="card" style="width:260px;height:240px;">
            <img
            style="max-height:80%; max-width:80%; margin:auto;"
              class="card-img-top img-fluid"
              src="../../../../assets/images/product/main/production_equipement/Screen_Printer/VERSAPRINT 2 ELITE.png"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                 VERSAPRINT 2 ELITE
              </h4>
              <h6  style="text-align: center ;font-size: 10px;" class="card-title">
               Particularly suitable for high inspection requirements
              </h6>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
        
       
       
      </div>

    
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
};
</script>